ol.pagination {
  list-style-type: none;
}

ol.pagination li {
  display: inline-block;
  &:before { 
  content: " "; 
  counter-increment: none; 
  }
}

.pagination a {
  display: inline-block;
  margin: 0 0.3em 0.5em 0;
  padding: 0.51em 0.5em;
  height: 2.2em;
  min-width: 2.2em;
  font-size: 1.1em;
  font-weight: 500;
  text-align: center;
  background-color: $white;
  border-bottom: 0.2em solid $shadow;
  &:hover {
    text-decoration: underline;
    background-color: $charcoal;
    color: $white;
    border-color: darken($shadow,10%);
  }
}

.pagination__current a {
  background-color: $mid-aqua;
  color: $white;
  border-bottom: 0.2em solid lighten($dark-aqua,10%);
  cursor: not-allowed;
  &:hover {
    text-decoration: none;
    color: $white;
    background-color: $mid-aqua;
    border-color: lighten($dark-aqua,10%);
  }
}

.pagination__previous a,
.pagination__next a {
  background-color: $mid-aqua;
  color: $white;
  border-bottom: 0.2em solid lighten($dark-aqua,10%);
  padding: 0.1em 0.1em;
  height: 2.2em;
  width: 2.2em;
}

.pagination__previous span,
.pagination__next span{
  font-size: 1.5em;
}

.pagination__previous.no-more a,
.pagination__next.no-more a {
  background-color: $shadow;
  color: darken($shadow,20%);
  border-bottom: 0.2em solid darken($shadow,15%);
  cursor: not-allowed;
  &:hover {
    text-decoration: none;
    color: darken($shadow,20%);
    border-color: darken($shadow,15%);
  }
}