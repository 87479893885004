/* Buttons
-------------------------------------------------------------- */
//skip to content
.skip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
        .btn-skip {
            height: 1px;
            width: 1px;
            position: absolute;
            overflow: hidden;
            top: -10px;
            left: 0;
            &:focus {
                position: fixed;
                height: auto;
                width: auto;
                margin:0 auto;
                display: block;
                left: 0;
                right: 0;
                top:5px;
                text-align: center;
            }
        }
}

/* Buttons / CTAs */

.cta {
  background-color: $aqua;
  border-radius: 4px;
  color: #fff;
  border-bottom: 0.2em solid darken($mid-aqua,10%);
  border-top: 0.1em solid lighten($aqua,15%);
  display: inline-block;
  padding: 0.6em 1.2em;
  font-weight: 500;
  text-transform: uppercase;
  @include transition(background-color,0.12s); // Kin Mixin
  &:hover, &:focus, &:active {
    background-color: lighten($dark-aqua,5%);
    border-bottom: 0.2em solid darken($dark-aqua,10%);
    color: #fff;
  }
}

/* Removes awkward default styles on some inputs for mobile */
input[type="button"], 
input[type="reset"], 
input[type="submit"] {
  -webkit-appearance: none;
   -moz-appearance: none;
        appearance: none; 
}

/* Removes awkward UA side borders set, and pads to right height */
button,
input[type="button"], 
input[type="reset"], 
input[type="submit"] {
  border-left: 0;
  border-right: 0;
  height: 3em;
}

.cta--charcoal {
  background-color: $charcoal;
  border-bottom: 0.2em solid darken($shadow,15%);
  border-top: 0.1em solid lighten($shadow,30%);
  &:hover {
    background-color: lighten($charcoal,18%);
  }
}

.cta--blue {
  background-color: $blue;
  border-bottom: 0.2em solid darken($blue,10%);
  border-top: 0.1em solid lighten($blue,25%);
  &:hover {
    background-color: lighten($blue,10%);
  }
}

.cta--purple {
  background-color: $purple;
  border-bottom: 0.2em solid darken($purple,12%);
  border-top: 0.1em solid lighten($purple,25%);
  &:hover {
    background-color: lighten($purple,8%);
  }
}

.cta--white, 
.home .main-search .cta {
  background-color: $white;
  color: $charcoal;
  border-bottom: 0.2em solid $shadow;
  border-top: 0.1em solid lighten($grey,45%);
  &:hover, &:focus, &:active {
    background-color: $aqua;
    border-bottom: 0.2em solid darken($mid-aqua,10%);
    border-top: 0.1em solid lighten($aqua,15%);
    color: #fff;
  }
}

.cta--disabled {
  background-color: $grey;
  color: darken($shadow,10%);
  border-bottom: 0.2em solid $shadow;
  border-top: 0.1em solid lighten($grey,5%);
  cursor: not-allowed;
  &:hover {
    background-color: $grey;
    cursor: not-allowed;
    text-decoration: none;
  }
}

.cta--open {
  background: transparent;
  border-radius: 0;
  color: $aqua;
  border: 0;
  &:hover {
    background: transparent;
    border: 0;
    color: $dark-aqua;
    }
  }