// = Add or remove Margin
//-----------------------------------------------------------------------------//

.push { margin:$baseline !important; }
.push--top { margin-top:$baseline !important; }
.push--right { margin-right:$baseline !important; }
.push--bottom { margin-bottom:$baseline !important; }
.push--left { margin-left:$baseline !important; }
.push--ends { margin-top:$baseline !important; margin-bottom:$baseline !important; }
.push--sides { margin-right:$baseline !important; margin-left:$baseline !important; }

.push-half { margin:$baseline/2 !important; }
.push-half--top { margin-top:$baseline/2 !important; }
.push-half--right { margin-right:$baseline/2 !important; }
.push-half--bottom { margin-bottom:$baseline/2 !important; }
.push-half--left { margin-left:$baseline/2 !important; }
.push-half--ends { margin-top:$baseline/2 !important; margin-bottom:$baseline/2 !important; }
.push-half--sides { margin-right:$baseline/2 !important; margin-left: $baseline/2 !important; }

.push-third--bottom { margin-bottom:$baseline/3 !important; }

.push-double--bottom { margin-bottom:$baseline !important; @media (min-width:$bp-small) {margin-bottom:$baseline * 2 !important;}}
.push-double--ends { margin-top:$baseline * 2 !important; margin-bottom:$baseline * 2 !important; }
.push-double--sides { margin-right:$baseline * 2 !important; margin-left:$baseline * 2 !important; }
.push-double--top { margin-top:$baseline * 2 !important; }
.push-double--right { margin-right:$baseline * 2 !important; }
.push-double--left { margin-left:$baseline * 2 !important; }

.push-triple--bottom {margin-bottom:$baseline * 3 !important;}

.push-quarter--bottom { margin-bottom:$baseline/4 !important;}
.push-quarter--top { margin-top:$baseline/4 !important;}

.flush { margin:0!important; }
.flush--top { margin-top: 0!important; }
.flush--right { margin-right: 0!important; }
.flush--bottom { margin-bottom:0!important; }
.flush--left { margin-left: 0!important; }
.flush--ends { margin-top: 0!important; margin-bottom:0!important; }
.flush--sides { margin-right: 0!important; margin-left:  0!important; }

// = Add or remove Padding
//-----------------------------------------------------------------------------//

.soft { padding:$baseline !important; }
.soft--top { padding-top:$baseline !important; }
.soft--right { padding-right:$baseline !important; }
.soft--bottom { padding-bottom:$baseline !important; }
.soft--left { padding-left:$baseline !important; }
.soft--ends { padding-top:$baseline !important; padding-bottom:$baseline !important; }
.soft--sides { padding-right:$baseline !important; padding-left: $baseline !important; }

.soft--right--large {
    @media (min-width:$bp-large) {
        padding-right:$baseline !important;
        }
    }

.soft--double-right--large {
    @media (min-width:$bp-large) {
        padding-right:$baseline * 2 !important;
        }
    }

.soft--triple-right--large {
    @media (min-width:$bp-large) {
        padding-right:$baseline * 6 !important;
        }
    }

.soft-double {
    padding:$baseline !important;
    @media (min-width:$bp-medium) {
        padding:$baseline * 2 !important;
        }
    }

.soft-double--medium {
    @media (min-width:$bp-medium) {
        padding:$baseline * 2 !important;
        }
    }

.soft-double--ends {
    padding-top:$baseline !important;
    padding-bottom:$baseline !important;
    @media (min-width:$bp-medium) {
        padding-top:$baseline * 2 !important;
        padding-bottom:$baseline * 2 !important;
        }
    }

.soft-double--top {
    padding-top:$baseline !important;
    @media (min-width:$bp-medium) {
        padding-top:$baseline * 2 !important;
        }
    }

.soft-double--bottom {
    padding-bottom:$baseline !important;
    @media (min-width:$bp-medium) {
        padding-bottom:$baseline * 2 !important;
        }
    }

.soft-triple--bottom {
    padding-bottom:$baseline * 3 !important;
}

.soft-gutter--bottom {
    padding-bottom:$baseline !important;
    @media (min-width:$bp-medium) {
        padding-bottom:$baseline * 6 !important;
        }
    }

.soft-double--sides {
    padding-right:$baseline !important;
    padding-left: $baseline !important;
    @media (min-width:$bp-medium) {
        padding-right:$baseline * 2 !important;
        padding-left: $baseline * 2 !important;
        }
    }

.soft-triple--sides {
    padding-right:$baseline !important;
    padding-left: $baseline !important;
    @media (min-width:$bp-medium) {
        padding-right:$baseline * 3 !important;
        padding-left: $baseline * 3 !important;
        }
    }

.soft-half { padding:$baseline/2 !important; }
.soft-half--top { padding-top:$baseline/2 !important; }
.soft-half--right { padding-right:$baseline/2 !important; }
.soft-half--bottom { padding-bottom:$baseline/2 !important; }
.soft-half--left { padding-left:$baseline/2 !important; }
.soft-half--ends { padding-top:$baseline/2 !important; padding-bottom:$baseline/2 !important; }
.soft-half--sides { padding-right:$baseline/2 !important; padding-left: $baseline/2 !important; }
.soft-quarter--ends { padding-top:$baseline/4 !important; padding-bottom:$baseline/4 !important; }
.soft-quarter--bottom { padding-bottom:$baseline/4 !important; }

.hard { padding: 0!important; }
.hard--top { padding-top:0!important; }
.hard--right { padding-right: 0!important; }
.hard--bottom { padding-bottom:0!important; }
.hard--left { padding-left:0!important; }
.hard--ends { padding-top:0!important; padding-bottom:0!important; }

// = Floats
//-----------------------------------------------------------------------------//

.center {
	margin: 0 auto;
}

.pull-left {
    float:left;
    }

.pull-right {
    float:right;
    }

.pull-right--medium {
    @media (min-width:$bp-medium) {
        float:right;
        }
    }

.pull-right--large {
    @media (min-width:$bp-large) {
        float:right;
        }
    }

.clearfix {
    @include clearfix;
    }

.block {
    display:block;
    }

.block--medium {
    display: block;
    @media (min-width:$bp-small) {
        display: inline-block;
    }
    @media (min-width:$bp-medium) {
        display: block;
    }
}

.inline-block {
    display:inline-block !important;
    }

.inline {
    display:inline;
    }

.relative {
    position:relative;
    }

.text-center {
	text-align: center;
}

.text-right {
    text-align:right;
    }

.text-left {
    text-align:left;
    }

.text-right--medium {
    @media (min-width:$bp-medium) {
        text-align:right;
        }
    }

.text-right--large {
    @media (min-width:$bp-large) {
        text-align:right;
        }
    }

.pull-up{
    @media (min-width:$bp-large) {
        margin-top:-8rem;
        z-index:9998;
    }
}

.flex__equal-height {
    width:100%;
    display:flex;
    flex-wrap:wrap;
        .col {
            float:none;
        }
        img{
            width:100%;
        }
}