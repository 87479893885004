/* Outside blocks
   Sometimes we'll want to add a heading or CTA outside
   of a layout block. For this we use .outside, which 
   should be applied to a container around the object */

.outside {
  width: 100%;
  padding-left: 1.4em; 
  // Enhanced layout:
  @media only all and (min-width: 41em) {
    width: 90%;
    margin: 0 auto;
    padding: 0;
    max-width: 80em;
  }
}

/* Blocks - Mobile-first styles */

.block {
  display: inline-block;
  margin-bottom: 1.5em;
  width: 100%;
}

.block--overlay {
  background-color: $white;
  border-bottom: 0.2em solid $shadow;
  }

/* /// Block colours */

.block--aqua {
  background-color: $mid-aqua;
  // shadow colour-context tweak
  border-bottom: 0.2em solid darken($mid-aqua,8%);
}

.block--dark-aqua {
  background-color: $dark-aqua;
  color: $white;
  // shadow colour-context tweak
  border-bottom: 0.2em solid darken($dark-aqua,7%);
}

.block--charcoal {
  background-color: $charcoal;
  color: $white;
  // shadow colour-context tweak
  border-bottom: 0.2em solid lighten($charcoal,20%);
}

/* /// Top trim - only for blocks with no header bar */

.block--trim-top-aqua {
  border-top: 0.4em solid $aqua;
}

.block--trim-top-dark-aqua {
  border-top: 0.4em solid $dark-aqua;
}

.block--trim-top-charcoal {
  border-top: 0.4em solid $charcoal;
}

/* /// Bottom trim should only be used on white blocks */

.block--trim-bottom-aqua {
  // block shadows are border-bottom so we use :after to 
  // allow bottom trim colors; works in IE8 and up, 
  // so ¯\_(ツ)_/¯ it's an enhancement!
  &:after {
    content: "";
    display: inline-block;
    float: left;
    width: 100%;
    height: 0.3em;
    margin-top: 0.4em;
    background-color: $mid-aqua;
  }
  // shadow colour-context tweak
  border-bottom: 0.2em solid darken($mid-aqua,8%);
}

.block--trim-bottom-dark-aqua {
  // block shadows are border-bottom so we use :after to 
  // allow bottom trim colors; works in IE8 and up, 
  // so ¯\_(ツ)_/¯ it's an enhancement!
  &:after {
    content: "";
    display: inline-block;
    float: left;
    width: 100%;
    height: 0.3em;
    margin-top: 0.4em;
    background-color: $dark-aqua;
  }
  // shadow colour-context tweak
  border-bottom: 0.2em solid darken($dark-aqua,7%);
}

.block--trim-bottom-charcoal {
  // block shadows are border-bottom so we use :after to 
  // allow bottom trim colors; works in IE8 and up, 
  // so ¯\_(ツ)_/¯ it's an enhancement!
  &:after {
    content: "";
    display: inline-block;
    float: left;
    width: 100%;
    height: 0.3em;
    margin-top: 0.4em;
    background-color: $charcoal;
  }
  // shadow colour-context tweak
  border-bottom: 0.2em solid lighten($charcoal,20%);
}

/* /// Block heading bars */

.block__heading {
  padding: 1em 1.4em;
  background-color: $charcoal;
  color: $white;
}

.block__heading .heading {
  font-size: 1.2em;
}

.block__heading--aqua {
  background-color: $mid-aqua;
}

.block__heading--dark-aqua {
  background-color: $dark-aqua;
}

/* Padding tweaks; if using a heading bar the same
colour as the block, give the title text a little more breathing room vertically */

.block--aqua .block__heading--aqua,
.block--dark-aqua .block__heading--dark-aqua,
.block--charcoal .block__heading {
  padding: 1.2em 1.4em 0.4em 1.4em;
}

.block--charcoal .block__heading--aqua,
.block--charcoal .block__heading--dark-aqua {
  padding: 0.7em 1.4em 0.6em 1.4em;
}

/* /// Block Content */

.block__content {
  padding: 1.2em 1.4em 1.4em 1.4em;
  margin: 0 0 0 0;
}

// for use with .block__image--right:
.block__content--left {
  width: 50%;
  float: left;
}

// for use with .block__image--left:
.block__content--right {
  width: 50%;
  float: right;
}

/* /// Blocks with Images */

// Examples use CSS background images set inline - 
// your usage may vary

.block__image {
  background-position: center;
  background-repeat: no-repeat;
}

.block__image--top {
  margin-bottom: 0.5em;
}

.block__image--bottom {
  margin-top: 0.5em;
}

.block__image--right {
  width: 50%;
  float: right;
}

.block__image--left {
  width: 50%;
  float: left;
}

.block__image--right, .block__image--left {
  background-position: center bottom;
}


// Arbitrary breakpoint from handheld -> full layout - 
// your choice should vary depending on your layout

@media only all and (min-width: 41em) {

/* Block changes */

.block {
  margin: 0 1.5% 2em 1.5%;
  float: left;
}

/* Layout Classes */

.block--25 {
  width: 22%;
}

.block--33 {
  width: 30.3%;
}

.block--40 {
  width: 37%;
}

.block--50 {
  width: 47%;
}

.block--60 {
  width: 57%;
}

.block--66 {
  width: 63.6%;
}

.block--75 {
  width: 72%;
}

.block--100 {
  width: 97%;
}

} // end min 41em;