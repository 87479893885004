/* Primary Navigation
-------------------------------------------------------------- */

@charset "utf-8";

.usp__nav {
    display: inline-block;
    overflow-x: scroll;
    white-space: nowrap;
    width: 100%;
    @media only all and (min-width: 46.25em) {
        float: left;
        overflow:hidden;
    }
    @media only all and (min-width: 68.5em) {
        width:auto;
    }
}

.usp__nav ul {
    display: inline-block;
    font-size: 0.8em;
    margin-top: 0.5em;
    width: 100%;
/* 	@media only all and (min-width: 20em) and (max-width: 68.499em) {
    	display: table;
    	width: 100%;
		} */
    @media only all and (min-width: 46.25em) {
        display: block;
        }
    @media only all and (min-width: 68.5em) {
        display: block;
        margin-left: 0.7em;
        margin-top: 0.3em;
        }
    @media only all and (min-width: 75em) {
        margin-left: 1em;
        }
    @media only all and (min-width: 80em) {
        margin-left: 2em;
        }
	}

.usp__nav li {
    display: inline-block;
    list-style-type: none;
    margin: 0;
/*     @media only all and (min-width: 20em) {
        float: left;
        margin-right: 0.5em;
        } */
    @media only all and (min-width: 46.25em) {
        margin-right: 1em;
        }
    @media only all and (min-width: 68.5em) {
        margin-right: 0;
        }
    @media only all and (min-width: 75em) {
        margin-right: 1em;
        }
    @media only all and (min-width: 80em) {
        margin-right: 2em;
        }
    }

.usp__nav li:last-child {
	@media only all and (min-width: 20em) {
        margin-right: 0;
        }
    }

.usp__nav li a {
    border: 0;
    border-radius: 4px;
    color: $mid-aqua;
    display: block;
    line-height: 1;
    padding: 0.5em;
    @media only all and (min-width: 41em) {
        font-size: 1em;
        padding: 0.5em;
        }
    @media only all and (min-width: 68.5em) { 
        padding: 1em;
        }
    }

.usp__nav li a:hover, 
.usp__nav li a:focus, 
.usp__nav li a.active {
    background: $aqua;
    color: #fff;
    }