// Path alias
$assetPath:                '/static';

// Colors
// -------------------------

// // Type
// // --------------------------------------------------
$thin:                      100;
$light:                     300;
$regular:                   400;
$semibold:                  600;
$bold:                      700;
$thick:                     800;

$baseline:                  1.5rem !default;
$base-font-family:          sans-serif !default;
$base-font-weight:          $regular !default;
$base-font-size:            100% !default;
$base-line-height:          1.5 !default;

// // Layout
// // --------------------------------------------------
$max-container-width:       1400px !default;
$gutter:				    30px !default;
$columns:                   12 !default;

// Breakpoints
// --------------------------------------------------
$bp-xsmall:                 40em !default;
$bp-small:                  48em !default;
$bp-medium:                 65em !default;
$bp-large:                  $max-container-width !default;

$warm-grey-1: #a69b94;
$warm-grey-2: #877972;
$warm-grey-3: #594646;
$grey: #666666;
$light-grey-1: #f5f5f5;
$light-grey-2: #e6e6e6;
$light-grey-3: #cccccc;
$mid-grey-1: #b3b3b3;
$mid-grey-2: #999999;
$mid-grey-3: #808080;
$dark-grey-1: #666666;
$dark-grey-2: #4d4d4d;
$dark-grey-3: #333333;
$white: #ffffff;
$black:#1B181C;
$off-black:#2a2a2a;