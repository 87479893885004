/* Grid
-------------------------------------------------------------- */
@function column-calc($num, $spacing:$gutter){
	@return calc(((99.999% / #{$columns}) * #{$num}) - (#{$spacing}));
}

@function column-calc-fallback($num){
	@return ((100 / $columns) * $num) - 5%;
}

@mixin column($num) {
	width: column-calc-fallback($num);
	width: column-calc($num);
}

.wrap {
    margin: 0 auto;
    max-width: 1240px;
    width:100%;
    @include clearfix();
    @media (min-width: $bp-medium) {
        padding-left:($gutter/2);
        padding-right:($gutter/2);
    }
}

.row {
    clear:both;
    &:after {
        content:'';
        display: table;
    }
    @media(min-width:$bp-small){
        margin-left:-$gutter/2;
        margin-right:-$gutter/2;
    }
}

.col {
    //max-width:$max-container-width;
    float:left;
    margin-left:$gutter/2;
    margin-right:$gutter/2;
    margin-bottom: 2em;
}

.col--inline {
    display: inline-block;
    margin-left:$gutter/2;
    margin-right:$gutter/2;
    margin-bottom: 2em;
}

.col--flex {
    display: flex;
    flex-direction: column;
    margin-left:$gutter/2;
    margin-right:$gutter/2;
    margin-bottom: 2em;
}

@media(min-width:$bp-small){
	.small-2 {
        @include column(2);
    }
    .small-3 {
        @include column(3);
    }
    .small-4 {
        @include column(4);
    }
    .small-5 {
        @include column(5);
    }
    .small-6 {
        @include column(6);
    }
    .small-7 {
        @include column(7);
    }
    .small-8 {
        @include column(8);
    }
    .small-9 {
        @include column(9);
    }
    .small-10 {
        @include column(10);
    }
    .small-11 {
        @include column(11);
    }
    .small-12 {
        @include column(12);
    }
}

.xsmall-4 {
    @media(max-width:$bp-small){
        @include column(4);
    }
}
.xsmall-5 {
    @media(max-width:$bp-small){
        @include column(6);
    }
}
.xsmall-6 {
    @media(max-width:$bp-small){
        @include column(6);
    }
}
.xsmall-8 {
    @media(max-width:$bp-small){
        @include column(8);
    }
}
.xsmall-11 {
    @media(max-width:$bp-small){
        @include column(11);
    }
}
.xsmall-12 {
    @media(max-width:$bp-small){
        @include column(12);
    }
}


.menu-12 {
    @media (max-width:899px) {
        @include column(12);
    }
}

.medium-2 {
    @media (min-width:$bp-medium) {
        @include column(2);
    }
}
.medium-3 {
    @media (min-width:$bp-medium) {
        @include column(3);
    }
}
.medium-4 {
    @media (min-width:$bp-medium) {
        @include column(4);
    }
}
.medium-5 {
    @media (min-width:$bp-medium) {
        @include column(5);
    }
}
.medium-6 {
    @media (min-width:$bp-medium) {
        @include column(6);
    }
}
.medium-7 {
    @media (min-width:$bp-medium) {
        @include column(7);
    }
}
.medium-8 {
    @media (min-width:$bp-medium) {
        @include column(8);
    }
}
.medium-9 {
    @media (min-width:$bp-medium) {
        @include column(9);
    }
}
.medium-10 {
    @media (min-width:$bp-medium) {
        @include column(10);
    }
}
.medium-12 {
    @media (min-width:$bp-medium) {
        @include column(12);
    }
}
.large-1 {
    @media (min-width:$bp-large) {
       @include column(1);
    }
}
.large-2 {
    @media (min-width:$bp-large) {
       @include column(2);
    }
}
.large-3 {
    @media (min-width:$bp-large) {
       @include column(3);
    }
}
.large-4 {
    @media (min-width:$bp-large) {
       @include column(4);
    }
}
.large-6 {
    @media (min-width:$bp-large) {
       @include column(6);
    }
}
.large-8 {
    @media (min-width:$bp-large) {
       @include column(8);
    }
}
.large-9 {
    @media (min-width:$bp-large) {
       @include column(9);
    }
}
.large-10 {
    @media (min-width:$bp-large) {
       @include column(10);
    }
}
.large-12 {
    @media (min-width:$bp-large) {
        float:none;
        width:auto;
    }
}

//Grid modifiers
.col--centre {
    margin:0 auto;
    padding:0 15px;
    @media (min-width:$bp-small) {
        padding:0;
    }
}
.col--padding {
    @media (min-width:$bp-small) {
        padding-top:10px;
    }
}