@charset "utf-8";

//// Brand

$aqua: #01B2CC;

$mid-aqua: #00A2BB;

$dark-aqua: darken($aqua,20%);


//// Modules / Body

$charcoal: #1A1919;

$white: #ffffff; // Yes, really.

$grey: #C3C3C3;

$shadow: #9f9f9f;


/// Calls to Action / Highlights

$purple: #612B8F;

$blue: #085FB1;

$feedback: #D9F2F9;

$error: #fcc;