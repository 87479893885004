.static h2 {
    margin-bottom: 0.5em;
    margin-top: 2em;
    }

.static h3 {
    margin-bottom: 0.5em;
    margin-top: 1em;
    }

.static h4 {
    margin-bottom: 0.25em;
    margin-top: 1em; 
    }

.static ul li {
    list-style-type: disc;
    margin-bottom: 0.5em;
    }

.static ol li {
    margin-bottom: 0.5em;
    }