.image {
  display: inline-block;
  margin: 0 0 2em 0;
  max-width: 100%;
}

.image img {
  max-width: 100%;
  display: block;
}

.image__caption {
  display: inline-block;
  width: 100%;
  background-color: lighten($grey,5%);
  padding: 0.3em 0.8em 0.4em 0.8em;
}

.image__caption a {
  color: $dark-aqua;
  border-color: $dark-aqua;
}