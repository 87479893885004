@charset "utf-8";

[hidden] { display: none; }

.visually-hidden {
	position: absolute;
	clip: rect(0, 0, 0, 0);
}

div.awesomplete {
	position: relative;
}

div.awesomplete > input {
	display: block;
}

div.awesomplete > ul {
	position: absolute;
	left: 0;
	z-index: 1;
	min-width: 100%;
	box-sizing: border-box;
	list-style: none;
	padding: 0;
	border-radius: .3em;
	margin: .2em 0 0;
	background: #fff;
	border: 1px solid $grey;
	box-shadow: .05em .2em .6em rgba(0,0,0,.2);
	text-shadow: none;
}

div.awesomplete > ul[hidden],
div.awesomplete > ul:empty {
	display: none;
}

@supports (transform: scale(0)) {
	div.awesomplete > ul {
		transition: .3s cubic-bezier(.4,.2,.5,1.4);
		transform-origin: 1.43em -.43em;
	}
	
	div.awesomplete > ul[hidden],
	div.awesomplete > ul:empty {
		opacity: 0;
		transform: scale(0);
		display: block;
		transition-timing-function: ease;
	}
}

div.awesomplete > ul > li {
	border-bottom: 1px solid $grey;
	color: #000;
	margin-bottom: 0;
	position: relative;
	padding: .5em .5em;
	cursor: pointer;
}

div.awesomplete > ul > li:hover, 
div.awesomplete > ul > li[aria-selected="true"] {
	background: $aqua;
	color: #fff;
}

div.awesomplete mark {
	background: $aqua;
	color: #fff;
}

div.awesomplete li:hover mark, 
div.awesomplete li[aria-selected="true"] mark {
	background: #fff;
	color: $aqua;
}