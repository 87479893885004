/* Header
-------------------------------------------------------------- */

.header {
  background: #fff;
  border-bottom: 0.4em solid $aqua;
  margin: 0 0 1.5em 0;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  position: relative;
  width: 100%;
  z-index: 999;
}

.header__contents {
  width: 93%;
  margin: 0 auto;
  max-width: 80em;
}

.header__title {
  float: left;
  margin-bottom: 0.5em;
  width: 50%;
  }

.header__title a {
  border: 0;
}

.header__title img {
  display: block;
  height: 50px;
  width: auto;
}

.header__logo {
  border-bottom: none;
  float: right;
  text-align: right;
  width: 50%;
}

.header__logo img {
  height: 28px;
  margin-top: 0.7em;
  width: auto;
  }

@media only all and (min-width: 41em) {
  
  .header__contents {
    width: 90%;
    margin: 0 auto;
    max-width: 80em;
  }
  
  .header__title {
    float: left;
    text-align: left;
    width: auto;
  }

.header__title a {
  font-size: 1.1em;
}

} // End min 41em

@media only all and (min-width: 56em) {
  
  .header {
    padding: 1.5em 0;
  }

  .header__title {
    //margin-bottom: 1em;
    margin-bottom: 0;
  }

  .header__logo {
    width: auto;
  }

  .header__logo img {
    height: 40px;
    margin-top: 0.3em;
  }

}
@media only all and (min-width: 68.5em) {

  .header__title {
    //margin-bottom: 0;
  }

}