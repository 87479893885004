@charset "utf-8";

.town__interrelationship__label {
    color: #777;
    float: left;
    font-size: 0.9em;
    font-style: italic;
    width: 50%;
    @media only all and (min-width: 30em) {
        width: 33.3333333333%;
        }
    }

.town__interrelationship__label:before, 
.town__interrelationship__label i:after {
    font-size: 0.7em;
    }

.town__interrelationship__label.label--interdependent {
    display: none;
    text-align: center;
    @media only all and (min-width: 30em) {
        display: block;
        }
    }

.town__interrelationship__label.label--independent {
    float: right;
    text-align: right;
    }

.town__interrelationship__wrapper {
    margin-bottom: 0.25em;
    margin-top: 0.25em;
    }

.town__interrelationship__indicator {
    background: #eee;
    border-radius: 20px;
    height: 40px;
    margin-bottom: 2px;
    position: relative;
    } 

.town__interrelationship h2 {
    margin-bottom: 0.7em;
    } 

.town__interrelationship__indicator h3 {
    font-size: 0.75em;
    font-weight: normal;
    margin: 0;
    padding-top: 0.75em;
    position: relative;
    text-align: center;
    text-shadow:
        -1px -1px 0 #eee,  
        1px -1px 0 #eee,
        -1px 1px 0 #eee,
        1px 1px 0 #eee;
    width: 100%;
    z-index: 2;
    @media (min-width:$bp-small) {
        font-size: 1em;
        padding-top: 0.4em;
    }
} 

.town__interrelationship__value, 
.town__interrelationship__average {
    left: 50%;
    position: absolute;
    top: 0;
    }

.town__interrelationship__value  {
    border: 2px solid #fff;
    border-radius: 50%;
    font-size: 0;
    height: 44px;
    margin-left: -22px;
    top: -2px;
    width: 44px;
    }

.town__interrelationship__value.town__cat--employment {
    background: #E67D1B;
    color: #E67D1B;
    } 

.town__interrelationship__value.town__cat--public {
    background: #8A9B0F;
    color: #8A9B0F;
    } 

.town__interrelationship__value.town__cat--commercial {
    background: #BD1550;
    color: #BD1550;
    } 

.town__interrelationship__value.town__cat--connectivity {
    background: #BD1550;
    color: #BD1550;
    } 

.town__interrelationship__value.town__cat--social {
    background: #015966;
    color: #015966;
    } 

.town__interrelationship__value.comparison {
    height: 34px;
    margin-left: -17px;
    opacity: 0.7;
    text-align: center;
    top: 3px;
    width: 34px;
    } 

.town__interrelationship__value.comparison span {
    color: #000;
    display: block;
    font-size: 14px;
    margin-top: 6px;
    } 

.town__interrelationship__average {
    background: #d7d7d7;
    background: rgba(0,0,0,0.3);
    font-size: 0;
    height: 40px;
    margin-left: -3px;
    width: 6px;
    } 

.town__interrelationship__indicator .value--neg6 {
    left: 0;
    margin-left: 0;
    } 

.town__interrelationship__value.comparison.value--neg6 {
    left: 5px;
    }

.town__interrelationship__indicator .value--neg5 {
    left: 8.33333333%;
    } 

.town__interrelationship__indicator .value--neg4 {
    left: 16.66666666%;
    } 

.town__interrelationship__indicator .value--neg3 {
    left: 25%;
    } 

.town__interrelationship__indicator .value--neg2 {
    left: 33.33333333%;
    } 

.town__interrelationship__indicator .value--neg1 {
    left: 41.66666666%;
    } 

.town__interrelationship__indicator .value--0 {
    left: 50%;
    } 

.town__interrelationship__indicator .value--pos1 {
    left: 58.33333333%;
    } 

.town__interrelationship__indicator .value--pos2 {
    left: 66.66666666%;
    } 

.town__interrelationship__indicator .value--pos3 {
    left: 75%;
    } 

.town__interrelationship__indicator .value--pos4 {
    left: 83.33333333%;
    } 

.town__interrelationship__indicator .value--pos5 {
    left: 91.66666666%;
    } 

.town__interrelationship__indicator .value--pos6 {
    margin-left: 0;
    left: auto;
    right: 0;
    }

.town__interrelationship__value.comparison.value--pos6 {
    right: 5px;
    }

.town__interrelationship__indicator .town__interrelationship__average.value--neg6 {
    left: 19px;
    } 

.town__interrelationship__indicator .town__interrelationship__average.value--pos6 {
    right: 19px;
    } 

.legend li {
    list-style-type: none;
    margin-bottom: 0.25em;
    }

.legend .town__interrelationship__value, 
.legend .town__interrelationship__average {
    display: inline-block;
    left: auto;
    position: relative;
    height: 14px;
    margin-left: 0;
    margin-right: 5px;
    vertical-align: middle;
    width: 14px;
    }

.legend .town__interrelationship__average {
    margin-left: 4px;
    margin-right: 10px;
    width: 6px;
    }