.error {
    background-color: #f2dede;
    border: 1px solid #ebccd1;
    color: #a94442;
    margin-bottom: 1em;
    padding: 1em;
    }

.explainer {
    border: 0;
    display: inline-block;
    }

.explainer i {
    color: #fff;
    }

.explainer i:before {
    color: $aqua;
    margin-right: 0;
    }

.town__intro {
    background: #A6DBF0;
    margin-bottom: 4em;
    margin-top: -1.5em;
    padding-top: 1em;
    position: relative;
    @media only all and (min-width: 41em) {
        padding-top: 2em;
        padding-bottom: 2em;
        }
    @media only all and (min-width: 41em) and (min-height: 60em) {
        padding-top: 4em;
        padding-bottom: 2em;
        }
    }

.town__intro .col, 
.town__intro .outside {
	position: relative;
	z-index: 2; 
	}

.town__intro h1 {
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    }

.town__intro .cta {
    margin-top: 1em;
    }

.block.town__map--small {
    display: none;
    @media only all and (max-width: 40.999em) {
        display: block;
        height: 300px;
        margin-left: 3%;
        margin-top: 3%;
        width: 94%;
        }
    }

.town__map {
    height: 100%;
	bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    }

.town__stats {
    margin-bottom: 0.4em;
    }

.town__LA {
    display: block;
    font-size: 0.3em;
    text-transform: uppercase;
    word-wrap: normal !important;
    -ms-word-break: normal !important;
    word-break: normal !important;
    -webkit-hyphens: none !important;
    -moz-hyphens: none !important;
    hyphens: none !important;
    opacity: 0.8;
    }

.town__population {
    display: inline-block;
    font-size: 0.9em;
    margin-bottom: 1em;
    margin-right: 2em;
    text-transform: uppercase;
    }

.town__population span {
    font-size: 1.2em;
    margin-left: 0.25em;
    }

.town__group {
    //border-top: 1px solid $grey;
    padding-top: 2em;
    @media only all and (min-width: 41em) {
        padding-bottom: 1em;
        padding-top: 3em;
        }
    }

.town__group {
    &:nth-child(odd) {
        background: $light-grey-1;
    }
}

.town__group__heading:before {
    font-size: 1.4em;
    }

.town__group__heading.icon-cab:before {
    margin-right: .4em;
}

.town__cat--demography .town__group__heading:before  {
    color: #00A2BB;
    }

.town__cat--employment .town__group__heading:before  {
    color: #E67D1B;
    }

.town__cat--public .town__group__heading:before  {
    color: #8A9B0F;
    }

.town__cat--commercial  .town__group__heading:before  {
    color: #BD1550;
    }

.town__cat--connectivity  .town__group__heading:before  {
    color: #BD1550;
    }

.town__cat--social  .town__group__heading:before  {
    color: #015966;
    }

.block.town__similar {
    @media only all and (min-width: 41em) {
        float: right;
        }
    }

.town__similar__add-remove {
    float: right;
    font-size: 0.7em;
    }

.highslide-container img {
    width: auto;
    }

.typologyGroup, 
.bgGroup {
    margin-bottom: 1em;
    overflow: scroll;
    }

.js .typologyGroup, 
.js .bgGroup {
    overflow: hidden;
    }

.indicator__wrapper {
    vertical-align: bottom;
}

.indicator__number {
    float: left;
    margin-right: 10%;
    //width: 45%;
    width:100%;
        @media only all and (min-width: 41em) {
            width:80%;
        }
    }

.indicator__number:last-child {
    margin-right: 0;
    }

.indicator__number__figure {
    border: 3px solid #ccc;
    border-radius: 6px;
    font-size: 3em;
    line-height: 1;
    margin-bottom: 0.25em;
    padding: 0.25em 0 0.4em 0;
    text-align: center;
    @media only all and (min-width: 41em) {
        font-size: 2em;
        }
    @media only all and (min-width: 55em) {
        font-size: 3em;
        }
    }

.indicator__number__comparison {
    margin-bottom: 0.5em;
    }

.indicator__number__comparison h4 {
    display: inline-block;
    padding-top: 0.3em;
    }

.indicator__number__comparison .indicator__number__figure {
    border-width: 2px;
    display: inline-block;
    font-size: 1em;
    padding: 0.4em 0.5em;
    }

.town__cat--demography .indicator__number__figure  {
    border-color: #00A2BB;
    }

.town__cat--employment .indicator__number__figure  {
    border-color: #E67D1B;
    }

.town__cat--public .indicator__number__figure  {
    border-color: #8A9B0F;
    }

.town__cat--commercial .indicator__number__figure  {
    border-color: #BD1550;
    }

.town__cat--connectivity .indicator__number__figure  {
    border-color: #BD1550;
    }

.town__cat--social .indicator__number__figure  {
    border-color: #015966;
    }

.indicator__info {
    text-align: center;
    }

.indicator__info span {
    display: block;
    }

.town__group {
    .heading {
        flex: 1 0 0px;
        -ms-flex: auto;
    }
}

.town__cat--commercial .town__group__heading {
    margin-bottom: 2em;
    }

.town__cat--social .town__group__heading {
    margin-bottom: 2em;
    }

.town__cat--connectivity .town__group__heading {
    margin-bottom: 2em;
    }

#comparison__choose .towns-similar__item {
    display: inline-block;
    margin-right: 1em;
    }

.comparison__choose__towns-added {
    margin-top: 2em;
    }

.towns-added__item {
    background-color: #d9edf7;
    border: 1px solid #bce8f1;
    border-radius: 4px;
    color: #31708f;
    display: inline-block;
    font-size: 0.9em;
    margin-right: 1em;
    padding: 0.2em 0.4em;
    text-transform: uppercase;
    }

.towns-added__item a {
    border: 0;
    color: #666;
    margin-left: 0.2em;
    }

.towns-added__item a:hover, 
.towns-added__item a:focus {
    color: #000;
    }

.highcharts-tooltip table {
    border-bottom: 0;
    margin-bottom: 0;
    }

.highcharts-tooltip tr {
    background: none;
    }

.town__extras__item {
    margin-bottom: 1em;
    }

.town__audit__promo {
    border: 3px solid $grey;
    padding: 1em;
    }

.footnotes__header {
    display: none;
    }

.chart-divider {
    padding: $baseline/2;
}

.chart-divider--bottom {
    margin-bottom: $baseline/2;
    padding-bottom: $baseline*2;
    position: relative;
    &:before {
        background: $light-grey-2;
        bottom: -$baseline/4;
        content: "";
        display: block;
        height: 1px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: calc( 100% - 30px);
    }
}

.chart-divider--right {
    position: relative;
    @media(min-width:$bp-small){
        &:after {
            background: $light-grey-2;
            content: "";
            display: block;
            height: calc(100% - 30px);
            position: absolute;
            right: -15px;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
        }
    }
}

.grid-divider--left {
    position: relative;
    @media(min-width:$bp-small){
        padding-left:$baseline*2;
        &:after {
            background: $light-grey-2;
            content: "";
            display: block;
            height: 100%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
        }
    }
}