@charset "utf-8";

//::::::::::::::::::::::::::
//::  Kin Defaults  ::::::::
//::::::::::::::::::::::::::
//::: PURPOSE: Default non-reset browser / box model fixes, 
//::: any other default choices we use across projects.

// Make everything to behave as IE box model - 
// see http://quirksmode.org/css/user-interface/boxsizing.html

* {
  -webkit-box-sizing: border-box; // Safari <= 5
  -moz-box-sizing: border-box; // Firefox <= 19
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

// Tell webkit to jog on with its auto-margins

* {
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-before: 0em;
  -webkit-padding-after: 0em;
  -webkit-padding-start: 0;
  -webkit-padding-end: 0;
}

// Remove the webkit glow on forms when focused on, but be sure 
// to replace it with an outline of some kind - this should then 
// be overridden when styling focus throughout the document 

// This ties in with the script tag in our header that sets an outline 
// on every item for focus, then if JS is present removes it;
// then uses JS to listen for keyboard use and re-applies for those
// tabbing through. By @decadecity - 
// https://decadecity.net/blog/2012/11/06/building-a-layered-ui#focus 

*:focus {
  outline: 0.1em solid orange;
}
.js *:focus {
  outline-width: 0px;
}
.keyboard *:focus {
  outline-width: 0.1em;
}

// Let us decide how to style form input submit buttons 
// on mobile browsers

input[type='submit']
{
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
}

// Clearfix

.clearfix:before, .clearfix:after {
  display: table;
  line-height: 0;
  content: "";
}

.clearfix:after {clear: both;}



body {
	font-size: 1.1em;
	line-height: 1.35;
	border: none;
	color: #333; 
	}

.container {
	margin-left: 3.5% !important;
	margin-right: 3.5% !important;
	width: auto !important;
	@media only all and (min-width: 41em) {
		width: 93% !important;
		margin-left: auto !important;
		margin-right: auto !important;
		max-width: 80em !important;
		max-width: calc(80em + 2%) !important;
		}
	}

img {
	height: auto;
	width: 100%;
	}

.away {
	position: absolute;
	left: -9999em;
	}

.small-print {
	font-size: 0.8em;
	}

.block__heading .heading {
	margin-bottom: 0;
	}

.outside {
	width: 97% !important;
	width: calc(97% - 2.8em) !important;
	}

.match-height__wrapper {
	@media only all and (min-width: 41em) {
		display: table;
		width: 100%;
		}
	}

.match-height__inner {
	@media only all and (min-width: 41em) {
		padding-left: 1.5%;
		padding-right: 1.5%;
		}
	}

.match-height__item, 
.block.match-height__item, 
.match-height__spacer {
	@media only all and (min-width: 41em) {
		display: table-cell;
		float: none;
		height: auto;
		vertical-align: top;
		}
	}

.match-height__spacer {
	@media only all and (min-width: 41em) {
		width: 3%;
		}
	}

.lister li {
	list-style-type: none;
	margin-bottom: 2px;
	}

.lister li a {
	background: #eee;
	border-bottom: 0;
	display: block;
	padding: 0.5em;
	}

.heading--inline {
	display: inline-block;
	margin-right: 0.5em;
	vertical-align: baseline;
	}

.explanation--inline {
	display: inline-block;
	vertical-align: baseline;
	}

.border--top {
	border-top: 1px solid $light-grey-3;
}