@charset "utf-8";

.home__hero {
	background: $dark-aqua;
	margin-top: -1.5em;
    padding-bottom: 3em;
    padding-top: 3em; 
    width: 100%;
	}

.cssvhunit .home__hero {
    @media only all and (min-height: 43em) {
   		left: 0;
        height: 100vh;
        margin-top: 0;
        padding-top: 10em;
        position: absolute;
    	top: 0;
        }
    @media only all and (min-width: 41em) and (min-height: 43em) {
        padding-top: 10em;
        padding-bottom: 2em;
        }
    @media only all and (min-width: 56em) and (min-height: 43em) {
        padding-top: 15em;
        }
    @media only all and (min-width: 68.5em) and (min-height: 43em) {
        padding-top: 10em;
        }
    @media only all and (min-width: 68.5em) and (min-height: 62em) {
        padding-top: 15em;
        } 
	}

.home__hero svg {
	max-width: 1200px;
	opacity: 0.25;
	position: absolute;
	right: 0;
	top: 0;
	}

@-webkit-keyframes appear_animation {
	0% { -webkit-transform: scale(0); }
	100% { -webkit-transform: scale(1); }
	}

@-webkit-keyframes pulse_animation {
	0% { -webkit-transform: scale(1); }
	30% { -webkit-transform: scale(1); }
	40% { -webkit-transform: scale(1.5); }
	50% { -webkit-transform: scale(1); }
	60% { -webkit-transform: scale(1); }
	70% { -webkit-transform: scale(1.3); } 
	80% { -webkit-transform: scale(1); }
	100% { -webkit-transform: scale(1); }
	}

.home__hero svg circle {
	-webkit-transform: scale(0);
	-webkit-transform-origin: 70% 70%;
	-webkit-animation: appear_animation 2s linear 2s 1, pulse_animation 5s linear 4s infinite;
	}

@for $i from 1 through 60 {
	$appear_time: (($i * 0.5) + 2); 
	$pulse_time: (($i * 0.5) + 4); 
	.home__hero svg circle:nth-child(#{$i}) { 
    	-webkit-animation: appear_animation 2s linear #{$appear_time}s 1, pulse_animation 5s linear #{$pulse_time}s infinite;
		}
	}

.home__hero .container {
	color: #fff;
	position: relative;
	z-index: 2;
	}

.home__hero__intro {
	font-size: 1em;
	font-weight: normal;
	@media only all and (min-width: 50em) {
		font-size: 1.5em;
		}
	@media only all and (min-width: 70em) {
		font-size: 2em;
		}
	}

.main-search {
    margin-bottom: 2em;
    }

.main-search .awesomplete {
	@media only all and (min-width: 25em) {
		float: left;
		width: 70%;
		}
	}

.main-search [type="search"] {
	border-color: $aqua;
	border-radius: 4px;
	color: #000;
	font-size: 1.25em;
	height: auto;
	margin-bottom: 0;
	opacity: 0.8;
	padding: 0.5em;
	@media only all and (min-width: 25em) {
		width: 70%;
		}
	@media only all and (min-width: 50em) {
		font-size: 1.5em;
		}
	}

.main-search .awesomplete [type="search"] {
	@media only all and (min-width: 25em) {
		width: 100%;
		}
	}

.main-search .cta {
	border-radius: 4px;
    margin-bottom: 0;
    margin-top: 1em;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    @media only all and (min-width: 25em) {
    	float: right;
    	margin-top: 0;
    	
    	width: 28%;
    	}
    @media only all and (min-width: 50em) {
		font-size: 1.2em;
		}
    }

.home__browse-link {
	color: #fff;
	display: inline-block;
	margin-right: 2em;
	&:hover, &:focus, &:active {
    	border-bottom: 1px solid #fff;
    	color: #fff;
  		}
	}

.home__more-link {
	display: none;
	}

.cssvhunit .home__more-link {
	@media only all and (min-height: 43em) {
		bottom: 0;
		display: block;
		left: 0;
		margin-bottom: 0;
		position: absolute;
		right: 0;
		text-align: center;
		}
	}

.home__more-link a {
	background: $dark-aqua;
	border: 2px solid #fff;
	border-bottom: 0;
	border-radius: 4px 4px 0 0;
	color: #fff;
	display: inline-block;
	font-size: 1.5em;
	padding: 0.5em 1em;
	&:hover, &:focus {
    	background: #fff;
    	color: $dark-aqua;
  		}
	}

.home__info {
	background: #fff;
	background: rgba(255,255,255,0.8); 
	padding-top: 2em;
	position: relative;
	z-index: 3;
	}

.cssvhunit .home__info {
	@media only all and (min-height: 43em) {
		position: absolute;
		top: 100vh;
		}
	@media only all and (min-height: 50em) {
		padding-top: 4em;
		}
	}

.home__section {
	margin-bottom: 2em;
	@media only all and (min-height: 50em) {
		margin-bottom: 4em;
		}
	}

.home__point {
	text-align: center;
	}

.feature-icon {
	background: $aqua;
	border-radius: 100px;
	color: #fff;
	display: inline-block;
	font-size: 60px;
	height: 2em;
	padding: 0.25em 0 0 0;
	text-align: center;
	width: 2em;
	-webkit-transform: scale(0.8);
	-ms-transform: scale(0.8);
	transform: scale(0.8);
	}

.feature-icon span:before {
	margin-right: 0;
	}

.feature-icon span {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Halflings';
	font-style: normal;
	font-weight: 400;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	}

.home__point h2 {
	margin-bottom: 0.5em;
	}

.home__point p {
	margin: 0 auto;
	max-width: 20em;
	}

.video-wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
	}

.video-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	}

.home__skyline {
	background: #fff url(../../assets/skyline.svg) center bottom no-repeat;
	background-size: cover;
	min-height: 500px;
	}

.home__section--quotes {
	border-bottom: 1px solid $grey;
	border-top: 1px solid $grey;
	padding-bottom: 2em;
	padding-top: 4em;
	width: 100%;
	}

.home__quote img {
	float: left;
	width: 25%;
	@media only all and (min-width: 70em) {
		width: 20%;
		}
	}

.home__quote p {
	font-weight: normal;
	@media only all and (min-width: 70em) {
		font-size: 1.3em;
		}
	}

.home__quote p, 
.home__quote cite {
	float: right;
	width: 70%;
	@media only all and (min-width: 70em) {
		width: 75%;
		}
	}

.home .footer {
	display: none;
	}

.home .footer.home__footer {
	display: block;
	}