@font-face {
  font-family: 'fontello';
  src: url('../../fonts/fontello.eot?48251874');
  src: url('../../fonts/fontello.eot?48251874#iefix') format('embedded-opentype'),
       url('../../fonts/fontello.woff2?48251874') format('woff2'),
       url('../../fonts/fontello.woff?48251874') format('woff'),
       url('../../fonts/fontello.ttf?48251874') format('truetype'),
       url('../../fonts/fontello.svg?48251874#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?48251874#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  //margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-right-bold:before { content: '\e800'; } /* '' */
.icon-up-dir:before { content: '\e801'; } /* '' */
.icon-down-dir:before { content: '\e802'; } /* '' */
.icon-archive:before { content: '\e803'; } /* '' */
.icon-left-bold:before { content: '\e804'; } /* '' */
.icon-charity:before { content: '\e805'; } /* '' */
.icon-factory:before { content: '\e806'; } /* '' */
.icon-briefcase:before { content: '\e807'; } /* '' */
.icon-users:before { content: '\e808'; } /* '' */
.icon-school:before { content: '\e809'; } /* '' */
.icon-chart-bar:before { content: '\e80a'; } /* '' */
.icon-download-cloud:before { content: '\e80b'; } /* '' */
.icon-file-pdf:before { content: '\e80c'; } /* '' */
.icon-file-excel:before { content: '\e80d'; } /* '' */
.icon-doc:before { content: '\e80e'; } /* '' */
.icon-gauge:before { content: '\e80f'; } /* '' */
.icon-info:before { content: '\e810'; } /* '' */
.icon-tree-2:before { content: '\e83f'; } /* '' */
.icon-cab:before { content: '\f1b9'; } /* '' */