/* Forms
-------------------------------------------------------------- */

/* Forms */

button,
input,
optgroup,
select,
textarea {
  color: inherit; /* 1 */
  font: inherit; /* 2 */
  margin: 0; /* 3 */
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
input[list],
input[type="file"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="range"],
input[type="search"],
textarea,
select {
  display: inline-block;
  height: 2.375em;
  width: 100%;
  padding: 0.375em 0.625em; 
  margin-bottom: 1.5em;
  /* ^ The 0.375em vertically centers text on FF, 
  ignored by Webkit */
  background-color: #fff;
  border: 0.1em solid $grey;
  box-shadow: none;
}

.checkbox {
  display: inline-block;
  margin: 1em 1.5em 2em 0 ;
}

.checkbox input {
  margin-right: 0.5em;
}

/* Removes awkward default styles on some inputs for mobile */
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
input[list],
textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; 
}

textarea {
  min-height: 4.0625em;
  padding-top: 0.375em;
  padding-bottom: 0.375em;
  margin-bottom: 1em; 
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[list]:focus,
textarea:focus,
select:focus {
  border: 0.1em solid $mid-aqua;
  outline: 0; 
}

label,
legend {
  display: block;
  font-size: 1em;
  margin-bottom: 0.4em;
  font-weight: 500; 
}

fieldset {
  padding: 0;
  margin-bottom: 1.5em;
  border-width: 0;
  border: 0;
}

fieldset ul {
  list-style-type: none;
}

input[type="checkbox"] {
  display: inline; 
}

input[type="radio"] {
  display: inline-block;
  margin-bottom: 0.5em;
}

.required {
  font-weight: 400;
  color: darken($shadow,16%);
}

.format {
  font-weight: 400;
  font-style: italic;
  color: darken($shadow,16%);
  margin-left: 0.75em;
}

input[type="search"] {
  box-sizing: border-box;
}

select[multiple] {
  height: auto;
}

textarea[disabled], input[disabled], select[disabled] {
  background-color: lighten($grey, 10%);
  color: $shadow;
}

textarea[disabled]:hover, input[disabled]:hover, select[disabled]:hover {
  cursor: not-allowed;
}

select:-internal-list-box option:checked {
  background-color: $aqua;
}

/* Form buttons are covered in the 'Buttons
  and calls to action' section above */

 .feedback {
	background-color: $feedback;
	border-bottom: 0.2em solid darken($feedback,8%);
	font-weight: 500;
	padding: 0.8em 1.4em;
	margin: 0 0 1.5em 0;
}

.feedback--error {
	background-color: $error;
	border-bottom: 0.2em solid darken($error,5%);
}