/* Type
-------------------------------------------------------------- */
// body,
// button,
// input,
// select,
// textarea {
//     font:$base-font-weight $base-font-size $body-font-family;
//     line-height:$base-line-height;
//     color:$body-font-color;
// }

// input,
// select,
// textarea {
//     font-size:.99rem;
//     line-height:1rem;
// }

// b,
// strong {
//     font-weight: $bold;
// }

// a:hover,
// a:focus,
// a:active {
// }

// .no-webfonts:not(.no-js) * {
//     font-family:sans-serif;
// }

@charset "utf-8";

.heading {
  font-weight: 700; //Medium - see below
  font-style: normal;
  margin: 0 0 0.5em 0;
}

.heading__large {
  font-size: 2.2em;
  line-height: 1.2;
}

.heading__medium {
  font-size: 1.5em;
  line-height: 1.2;
  @media only all and (min-width: 41em) {
        font-size: 1.7em;
      }
}

.heading__small {
  font-size: 1.15em;
  line-height: 1.2;
}

.heading__graph {
  font-size: 1em;
  line-height: 1.2;
  min-height: $baseline*2;
}

.sub-heading__graph {
    display: block;
    font-size: 0.8em;
    opacity: 0.8;
}

.town__group {
    .heading-borders__wrapper {
        h2 {
            background: #fff;
            float: left;
            padding-right: $baseline/2;
            position: relative;
            z-index: 1;
        }
        &:before,
        &:after {
            background: $light-grey-2;
        }
    }
    &:nth-child(odd) {
        .heading-borders__wrapper {
            h2 {
                background: $light-grey-1;
                float: left;
                padding-right: $baseline/2;
                position: relative;
                z-index: 1;
            }
            &:before,
            &:after {
                background: $light-grey-3;
            }
        }
    }
}

.heading-borders__wrapper {
    position: relative;
    &:before {
        background: $light-grey-3;
        content: "";
        height: 1px;
        left: -$baseline;
        position: absolute;
        top: 27px;
        width: $baseline/2;
        z-index: 0;
    }
    &:after {
        background: $light-grey-3;
        content: "";
        height: 1px;
        position: absolute;
        right: 0;
        top: 27px;
        width: 90%;
        z-index: 0;
    }
}

h1, 
h2, 
.header__title span, 
.indicator__number__figure, 
.brand-type, 
.usp__nav li a {
  font-family: 'Merriweather', serif;
  font-weight: bold;
}

h3 {
  font-weight: bold;
  }

p,
ul,
ol,
.text {
  font-style: normal;
  font-weight: 400;
}

p,
.text {
  margin-bottom: 0.75em;
}

.text__lead {
  font-size: 1.3em;
}

.text__small {
  font-size: 1em;
}

.text--aqua {
  color: $mid-aqua;
  //WCAG 2 AA Compliant only at >=18pt; ratio 4.31 against $white
}

b, 
strong {
  font-weight: bold;
}

i, 
em {
  font-style: italic;
}

a {
  color: $mid-aqua;
  border-bottom: 0.1em dotted $mid-aqua;
  text-decoration: none;
  -moz-transition:all 0.3s linear;
  -webkit-transition:all 0.3s linear;
  transition:all 0.3s linear;
  &:hover, &:focus {
      border-color: $dark-aqua;
      color: $dark-aqua;
      }
}

.link--dark-aqua {
  color: $dark-aqua;
  border-bottom: 0.1em dotted $dark-aqua;
}

.link--aqua {
  color: $aqua;
  border-bottom: 0.1em dotted $aqua;
}

.link--white {
  color: $white;
  border-bottom: 0.1em dotted $white;
}

//// Franklin Gothic URW - Typekit Weights and Styles available 
// in the STP Kit for reference;

/* Light 
  font-weight: 300;
  font-style: normal;

// Book 
  font-weight: 400;
  font-style: normal;

// Book Italic 
  font-weight: 400;
  font-style: italic;

// Medium 
  font-weight: 500;
  font-style: normal;
  
font-family: "franklin-gothic-urw";

//////// */

@charset "utf-8";

// Font loading and fallback - some of the commented out 
// lines are potential adjustment tools to tweak the fallback 
// font to mimick metrics of the webfont for less FOUT issues

// Fallback / load-in font
body,
.wf-loading body,
.wf-inactive body {
  font-family: 'Source Sans Pro', Frutiger, "Frutiger Linotype", Univers, 
  Calibri, "Myriad Pro", Myriad, 
  "DejaVu Sans Condensed", "Liberation Sans", "Nimbus Sans L", 
  Tahoma, Geneva, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  //letter-spacing: -0.04em;
}

// Loaded Webfont
.wf-active body {
  font-family: 'Source Sans Pro', sans-serif;
}

// Blockquote

.quote {
  margin: 1.5em 0;
  border-top: 0.1em solid;
  border-bottom: 0.1em solid;
  border-color: lighten($grey,10%);
  padding: 1em 1em;
  font-style: italic;
  font-size: 1.4em;
  line-height: 1.3em;
  color: $mid-aqua;
}

blockquote.quote__blockquote {
  margin: 0; // Firefox default override
}

.quote__blockquote {
  &:before {
    content: '“';
  }
  &:after {
    content: '”';
  }
  &:before,
  &:after {
    font-size: 1.6em;
    vertical-align: -0.2em;
    margin-left: -0.1em;
    color: $grey;
  }
}

.quote em {
  font-style: normal;
}

.quote__caption {
  font-style: italic;
  font-size: 0.8em;
  color: $charcoal;
  margin-top: 0.8em;
  &:before {
    content: "— ";
  }
}

// List unordered

ul {
  list-style: circle inside; 
  padding-left: 0;
  margin: 0; 
  padding: 0;
}

ul ul,
ul ol {
  margin: 1em 0 1em 2em;
  font-size: 1em;
}

li {
  margin-bottom: 1em; 
}

// List ordered


ol {
  list-style: decimal inside;  
  padding-left: 0;
  margin: 0; 
  padding: 0;
}

ol ol,
ol ul {
  margin: 1em 0 1em 2em;
  font-size: 1em;
}

li {
  margin-bottom: 1em; 
}

html\:abbr, abbr {
  border-bottom: 0.1em dotted;
  cursor: help;
}

.a-z-wrapper {
    //border-bottom: 1px solid $grey;
}

.a-z-list {
    list-style: none;
    margin-top: $baseline/6;
    li {
        float: left;
        padding-right: $baseline/2;
        width: 100%;
        @media(min-width:$bp-small){
            padding-right: $baseline;
            width: 50%;
        }
        @media(min-width:$bp-medium){
            width: 33.33%;
        }
        @media(min-width:$bp-large){
            width: 25%;
        }
    }
}